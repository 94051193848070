.heroCalender__drop{
    position: absolute;
    top: 100%;
    left: 0px;
    z-index: 1;
}

.inputCalender__drop{
    position: absolute;
    top: 130%;
    left: 0px;
    z-index: 1;
}

@media (max-width:650px) {
    .heroCalender__drop,
    .inputCalender__drop{
        scale: .7;
        left: -64px;
    }

    .inputCalender__drop{
        top: 14px;
    }
}


.rmdp-day span {
    text-align: center !important;
    font-size: .95rem !important;
}

.rmdp-day {
    height: 40px !important;
    width: 40px !important;

}

.rmdp-day.rmdp-today span {
    background-color: var(--solid-primary-100) !important;
    color: #fff !important;
    font-size: .95rem !important;
}

.rmdp-range {
    background-color: var(--solid-primary-100) !important;
}

.rmdp-wrapper {
    border-radius: 0.75rem !important;
}

.rmdp-week-day {
    color: var(--solid-primary-100) !important;
}

.rmdp-arrow {
    border: solid var(--solid-primary-100) !important;
    border-width: 0 2px 2px 0 !important;
    position: relative !important;
    top: 2px !important;
}

.rmdp-arrow-container:hover{
    background-color: #e3ecfa !important;
    border-color: white !important;
}

@media (max-width:440px) {
    .inputCalender__drop{
        scale: .58 !important;
        top: -10px !important;
        left: -125px !important;
    }
}